import mixpanel from 'mixpanel-browser';
import { getEngagementData } from './customhooks/usePageEngagement';

let isInitialized = false;

const ENVIRONMENTS = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production'
  };
  
const MIXPANEL_CONFIG = {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
    batch_requests: true
};

const getCurrentEnvironment = () => {
const isLocalhost = 
    window.location.hostname === 'localhost' || 
    window.location.hostname === '127.0.0.1';

    return isLocalhost ? ENVIRONMENTS.DEVELOPMENT : ENVIRONMENTS.PRODUCTION;
};
  
const getMixpanelToken = (environment) => {
const tokens = {
    [ENVIRONMENTS.DEVELOPMENT]: process.env.REACT_APP_MIXPANEL_DEV_PROJECT_TOKEN,
    [ENVIRONMENTS.PRODUCTION]: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
};
return tokens[environment];
};
  
const mixpanelService = {
    initMixpanel() {
        try {
        const environment = getCurrentEnvironment();
        const token = getMixpanelToken(environment);

        if (!token) {
            throw new Error(`Mixpanel token is missing for ${environment} environment`);
        }

        mixpanel.init(token, MIXPANEL_CONFIG);
        isInitialized = true;
        console.log(`Mixpanel initialized in ${environment} environment`);
        } catch (error) {
        console.error('Failed to initialize Mixpanel:', error.message);
        isInitialized = false;
        }
    },

  getIsInitialized() {
    return isInitialized;
  },

  identifyUser(userId, properties = {}) {
    if (!isInitialized) {
        console.warn("Mixpanel is not initialized.");
        return;
    }
    mixpanel.identify(userId);
    mixpanel.people.set(properties);
  },

  setUserProperties(properties = {}) {
    mixpanel.register(properties);
  },

  trackEvent(eventName, properties = {}) {
    if (!isInitialized) {
        console.warn("Mixpanel is not initialized.");
        return;
    }
    const engagementData = getEngagementData();
    mixpanel.track(eventName, {
        ...properties,
        ...engagementData
    });
  },

  reset() {
    mixpanel.reset();
  },
};

export default mixpanelService;