import React, { useState } from "react";
import { Drawer, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LoginIcon from '@mui/icons-material/Login';
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase.js'; 
import { LogoutOutlined } from "@mui/icons-material";
import { useAuth } from "../statemanagement/authContext.js";
import { useStateValue } from "../statemanagement/StateProvider.js";
import { actionTypes } from "../statemanagement/reducer.js";
import mixpanelService from "../mixpanelService.js";
import { usePageEngagement } from "../customhooks/usePageEngagement.js";

const RightSidebar = () => {
    const [open, setOpen] = useState(false);
    const [{userCred}, dispatch] = useStateValue();
    const {user} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    usePageEngagement();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            dispatch({ type: actionTypes.SET_UID, uid: null });
            dispatch({ type: actionTypes.SET_USER_CRED, userCred: null });
            dispatch({ type: actionTypes.SET_USER, user: null });
            localStorage.removeItem('uid');
            localStorage.removeItem('userCred');
            localStorage.removeItem('user');
            navigate("/");
            console.log("logged out");
            mixpanelService.trackEvent('Logout Success')
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const drawerMenu = () => {
        const baseMenu = [
            { text: "Terms & Conditions", icon: <ArticleIcon />, nav: "/terms-of-service", key: "terms" },
            { text: "Blogs", icon: <ArticleIcon />, nav: "/blogs", key: "blogs" },
            { text: "Privacy Policy", icon: <ArticleIcon />, nav: "/privacy", key: "privacy" },
            { text: "Support", icon: <ContactSupportIcon />, nav: "/contact-us", key: "support" },
            { text: "Refund & Cancellation", icon: <CancelIcon />, nav: "/cancellation", key: "cancellation" }
        ];

        const userMenu = user
            ? [
                { text: "Logout", icon: <LogoutOutlined />, nav: "", key: "logout", action: handleLogout },
                ...baseMenu
            ]
            : [
                { text: "Login", icon: <LoginIcon />, nav: "/auth/login", key: "login" },
                ...baseMenu
            ];

        return userMenu;
    };

    const handleDrawerClick = () => {
        setOpen(true);
        mixpanelService.trackEvent('Sidebar Opened', {
            logged_in: userCred ? true: false,
        });
    }

    return (
        <div>
            <Button
                startIcon={<MenuIcon style={{ color: "black", fontSize: "1.5rem" }} />}
                onClick={handleDrawerClick}
            ></Button>
            <Drawer
                onClose={() => setOpen(false)}
                anchor="right"
                open={open}
            >
                <ListItem key={"home"} disablePadding>
                    <ListItemButton onClick={() => navigate("/")}>
                        <ListItemIcon>
                            <img src="/icons/horoscope.png" alt="AstroSky" style={{ paddingRight: "3.5%", width: "30px", height: "30px" }} />
                        </ListItemIcon>
                        <ListItemText>
                            HiAstro
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <Divider />
                {
                    drawerMenu().map((u) => (
                        <ListItem key={u.key} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    if (u.key === 'logout') {
                                        u.action(); 
                                    } else {
                                        navigate(u.nav);
                                        mixpanelService.trackEvent('Clicked Login')
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    {u.icon}
                                </ListItemIcon>
                                <ListItemText>
                                    {u.text}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </Drawer>
        </div>
    );
};

export default RightSidebar;
