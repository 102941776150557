import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Keep track of both current and previous page timers
const pageTimers = {
    currentPath: window.location.pathname,
    startTime: Date.now(),
    previousPath: null,
    previousEngagement: 0
};

// Get the previous page's engagement data
export const getEngagementData = () => ({
    page: pageTimers.previousPath || pageTimers.currentPath,
    page_engagement: pageTimers.previousEngagement || 0
});

// Hook to manage timer resets on path changes
export const usePageEngagement = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== pageTimers.currentPath) {
            // Calculate engagement for the page we're leaving
            pageTimers.previousPath = pageTimers.currentPath;
            pageTimers.previousEngagement = Math.floor((Date.now() - pageTimers.startTime) / 1000);
            
            // Reset timer for new page
            pageTimers.currentPath = location.pathname;
            pageTimers.startTime = Date.now();
        }
    }, [location.pathname]);

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            pageTimers.previousPath = pageTimers.currentPath;
            pageTimers.previousEngagement = Math.floor((Date.now() - pageTimers.startTime) / 1000);
        };
    }, []);

    return getEngagementData;
};