import React, { useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import "../pages/BasicChat.css";
import "./PlacesAutocomplete.css";

const PlacesAutocomplete = ({ edit = false }) => {
  const [{city, geo, userCred}, dispatch] = useStateValue();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(()=> {
    const setCurrentCity = async() => {
      const storedCity = localStorage.getItem('city');
      const storedGeo = localStorage.getItem('geo') ? JSON.parse(localStorage.getItem('geo')) : null;
      if (storedCity!== null && storedGeo!==null) {
        dispatch({ type: actionTypes.SET_USER_CITY, city: storedCity });
        setValue(storedCity, false); 
      }

      if (storedGeo!==null && storedCity!==null) {
        dispatch({ type: actionTypes.SET_USER_GEO, geo: storedGeo });
      }
      else{
        try{
          const response = await fetch('https://ipinfo.io/json');
          const data = await response.json();
          const currCity = data.city;
          if(currCity){
            const result = await getGeocode({address: currCity});
            const {lat, lng} = getLatLng(result[0]);
            dispatch({ type: actionTypes.SET_USER_CITY, city: currCity });
            setValue(currCity, false); 

            if(lat && lng){
              dispatch({ type: actionTypes.SET_USER_GEO, geo: {lat: lat, lon:lng} });
            }
          }
        } catch (error){
          console.error('Error fetching current city or geo', error);
        }
      }
    }
    setCurrentCity();
  }, [dispatch, setValue]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    window.gtag('event', 'Places');
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      dispatch({
        type: actionTypes.SET_USER_GEO,
        geo: { lat: lat, lon: lng }
      });
      dispatch({ type: actionTypes.SET_USER_CITY, city: description });
    });
  };

  return (
    <> 
      <div style={{ fontSize: "large" }}>
        {!edit && <p style={{ fontWeight: "500" }}>Enter Your Place of Birth</p>}
        {edit && <p style={{ fontWeight: "500" }}>Edit Your Place of Birth</p>}
      </div>
      <br/> 
      <div ref={ref} className="autocompleteContainerStyle">
        <input
          className="inputStyle"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter Place of Birth"
        />
        {status === "OK" && (
          <div className="dropdownContainerStyle">
            {data.map((suggestion) => {
              const {
                place_id,
                structured_formatting: { main_text, secondary_text },
              } = suggestion;

              return (
                <div
                  key={place_id}
                  className="dropdownItemStyle"
                  onMouseDown={handleSelect(suggestion)} // Changed to onMouseDown for better UX
                >
                  <strong>{main_text}</strong> <small>{secondary_text}</small>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default PlacesAutocomplete;
