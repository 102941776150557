import { Button, Rating } from "@mui/material";
import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { useNavigate } from "react-router-dom";
import "./Entity.css";
import ForumIcon from '@mui/icons-material/Forum';
import mixpanelService from "../mixpanelService";
import { usePageEngagement } from "../customhooks/usePageEngagement";


const Entity = ({ entityName, entityCode, entityDescription, entityImageSrc, app_category, entityRating, sampleQuestions, chatDisplay = "Chat", orders, entityExpertise }) => {

    const [{ entity, horoscope, dob, tob, city, userCred }, dispatch] = useStateValue();

    const navigate = useNavigate();
    usePageEngagement();

    const clickEntity = () => {
        if (!dob || !tob || !city) {
            navigate("/edit");
            mixpanelService.trackEvent('Viewed Edit Page',{
                referrer: 'entity click',
                logged_in: userCred ? true:false
            })
            return;
        }

        window.gtag('event', entityCode);
        console.log(`Event: ${entityCode}`);
        mixpanelService.trackEvent('Clicked Entity',{
            entity: entityName,
            category: app_category,
            entity_category: entityExpertise,
            logged_in: userCred ? true: false,
        })

        if (entityCode != "guruji") {
            dispatch({ type: actionTypes.SET_ENTITY, entity: entityCode })
        }

        dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
        dispatch({ type: actionTypes.SET_LOADING, loading: true });

        if(entityExpertise){
            navigate(`/free/${entityExpertise}-chat/${entityCode}`);
        } else {
            navigate(`/chat/${entityCode}`);
        }
    }


    return (<div className="entityContainer">

        <img className="entityImage"
            src={entityImageSrc} alt={entityName} onClick={clickEntity} />


        <br /><br />

        <p style={{ fontWeight: "500", fontSize: "1.3rem", marginBottom: "5px" }} onClick={clickEntity}  >{entityName} </p>

        <div style={{ color: "gray", display: "flex", flexDirection: "row", alignItems: "center", "justifyContent": "center", marginBottom: "30px", fontWeight: "400", fontSize: "1.1rem" }} onClick={((_) => clickEntity())} >{entityDescription}</div>


        <Button className="discoverChatStyle" style={{marginBottom:"10px"}} onClick={clickEntity}>{chatDisplay}</Button>

        <br /> 
        
        {/* <br /> */}


        {/* { category == "ultra" &&  <Button className="discoverChatStyle" onClick={(e) => {

            if(!horoscope){
                navigate("/edit");
                return;
            }

            dispatch({type: actionTypes.SET_CATEGORY, category:"celeb"});
            dispatch({type: actionTypes.SET_ENTITY, entity:entityCode});
            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
            dispatch({ type: actionTypes.SET_LOADING, loading: true });
            window.gtag('event', entityCode);
            navigate(`/chat/${entityCode}`);
        }}>Chat</Button> } */}


        {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
            <Rating
                sx={{ marginTop: "10px !important", marginRight: "10px" }}
                name="simple-controlled"
                value={entityRating}
                precision={0.5}
                onChange={(event, newValue) => {
                }}

            />
            <p style={{ marginLeft: "10px", color: "gray", fontSize: "1rem", fontWeight: "400" }}> {orders}</p>
            <p style={{justifyContent: "center"}}><ForumIcon/></p>
        </div>
         */}
        <div className="entityRatingStyle">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Rating
                        // sx={{ marginTop: "10px !important" }}
                        name="simple-controlled"
                        value={entityRating}
                        precision={0.5}
                        onChange={(event, newValue) => {}}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "5px", color: "gray", fontSize: "1rem", fontWeight: "400" }}>{orders}</p>
                    <ForumIcon sx={{opacity:"0.6"}} />
                </div>
            </div>



         <br />


    </div>);
}

export default Entity;