import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { actionTypes } from "../statemanagement/reducer";
import { useStateValue } from "../statemanagement/StateProvider";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RateReviewIcon from '@mui/icons-material/RateReview';
import mixpanelService from "../mixpanelService";
import { usePageEngagement } from "../customhooks/usePageEngagement";

const AppFooter = ({ feedback=true, home=false, showSearch = true, showHome = true, shareUrl = "", showPost = true, showDiscover = true, showNumerology = false, showWrite=false, showBack=false, more=true }) => {
    const location = useNavigate(); 
    usePageEngagement();
    const [{userCred}, dispatch] = useStateValue();

    return (
        <div className="footer" style={{ zIndex:"1000", background: "white", display: "flex", position: "fixed", bottom: "0", width: "100%" }}>
            <div className="footer__inner" style={{ margin: "auto", alignItems: "center", display: "flex", paddingTop: "0.5rem", color: "white", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

            { false && <Button
                    style={{
                        // paddingRight: "20px",
                        // paddingLeft: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                        maxWidth:"50px"
                    }}
                    onClick={async _ => {
                        //window.gtag('event', 'feedback');
                        location("/");
                    }}>
                    <img src="/icons/horoscope.png" alt="HiAstro" style={{width:"40px", height:"40px", borderRadius:"10px", textDecoration: "none" }} />
                    {/* <div style={{ fontSize: "15px", opacity: "1", marginTop: "2px",  }}>Feedback</div> */}
                    <div style={{ opacity: "1", marginTop: "2px" }}></div>
                </Button>}
                {/* <Button
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                             const url = "https://astrosky.co.in";
                         //   const url = "https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            window.open(whatsappUrl, '_blank');
                            window.gtag('event', 'Share');
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "40px", textDecoration: "none" }} />
                    <div style={{ fontSize: "15px", opacity: "1", marginTop: "2px",  }}>Share</div>
                </Button> */}


               { <Button
                    style={{
                        // paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        window.gtag('event', 'astrologers');
                        location("/");
                    }}>
                   <img src="/icons/priest.png" alt="Constellation" style={{ width:"40px", height:"40px", textDecoration: 'none' }} />
                    <div style={{ opacity: '1', marginTop: '5px', textAlign: 'center' }}>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>View</div> */}
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}></div>
                    </div>
                </Button>}

                {false && <Button startIcon={<img src="/icons/hindu-wedding.png" alt="Constellation" style={{marginLeft:"8px",  width:"35px", height:"35px", textDecoration: 'none' }} />}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        window.gtag('event', 'Refine_PA', {
                            event_category: 'Refine_PA',
                            event_label: "Report", 
                        });
                        //alert("test");
                        window.location = "https://www.hiastro.co.in/kundli-matching";
                        // location("/kundli-matching");
                        
                    }}>
                        
                   {/* <img src="/icons/constellation.png" alt="Constellation" style={{ width: '40px', textDecoration: 'none' }} /> */}
                    <div style={{ opacity: '1', marginTop: '3px', textAlign: 'center' }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}></div>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Patri Details</div> */}
                    </div>
                </Button>}


                {/* <Button
                    style={{
                        // paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                                location("/week");
                    }}>
                   <img src="/icons/zodiac.png" alt="Constellation" style={{ width:"40px", height:"40px", textDecoration: 'none' }} />
                    <div style={{ opacity: '1', marginTop: '5px', textAlign: 'center' }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>My Week</div>
                    </div>
                </Button> */}

{false && <Button startIcon={<img src="/icons/constellations.png" alt="Constellation" style={{marginLeft:"8px",  width:"40px", height:"40px", textDecoration: 'none' }} />}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        // window.gtag('event', 'ria');
                        //         dispatch({type: actionTypes.SET_ENTITY, entity:"ria"})
                        //         dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
                        //         dispatch({ type: actionTypes.SET_LOADING, loading: true });
                        //         location("/chat/ria");
                        if(!!userCred && userCred.tier === "premium"){
                            window.gtag('event', 'ria');
                            dispatch({type: actionTypes.SET_ENTITY, entity:"ria"})
                            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
                            dispatch({ type: actionTypes.SET_LOADING, loading: true });
                            location("/chat/ria");
                        }
                        
                        if(!userCred){
                            location("/signin")
                        }
                        if(!!userCred && userCred.tier === "free"){
                            location("/profile");
                        }
                        
                    }}>
                        
                   {/* <img src="/icons/constellation.png" alt="Constellation" style={{ width: '40px', textDecoration: 'none' }} /> */}
                    <div style={{ opacity: '1', marginTop: '5px', textAlign: 'center', marginLeft:"0px !important" }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>My Week</div>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Patri Details</div> */}
                    </div>
                </Button>}

              

                {<Button startIcon={<CalendarMonthIcon style={{width:"40px", height:"40px", marginLeft:"6px", }}/>}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/edit");
                        mixpanelService.trackEvent('Viewed Edit Page',{
                            referrer: 'footer-icon click',
                            logged_in: userCred ? true: false,
                        })
                    }}>
                        
                   {/* <img src="/icons/constellation.png" alt="Constellation" style={{ width: '40px', textDecoration: 'none' }} /> */}
                    <div style={{ opacity: '1', marginTop: '3px', textAlign: 'center' }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}></div>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Patri Details</div> */}
                    </div>
                </Button>}

                

                {false && <Button startIcon={<img src="/matrimony.png" alt="Constellation" style={{marginLeft:"8px",  width:"40px", height:"40px", textDecoration: 'none' }} />}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        if(!userCred) location("/signin");
                        if(!!userCred && (userCred.tier === "free" || userCred.tier === "premium")) location("/matrimony");
                    }}>
                        
                   {/* <img src="/icons/constellation.png" alt="Constellation" style={{ width: '40px', textDecoration: 'none' }} /> */}
                    <div style={{ opacity: '1', marginTop: '3px', textAlign: 'center' }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Matrimony</div>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Patri Details</div> */}
                    </div>
                </Button>}

                


              

                {false && <Button startIcon={<RateReviewIcon style={{width:"40px", height:"40px", marginLeft:"6px", }}/>}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={_ => {
                        location("/feedback");
                    }}>
                        
                   {/* <img src="/icons/constellation.png" alt="Constellation" style={{ width: '40px', textDecoration: 'none' }} /> */}
                    <div style={{ opacity: '1', marginTop: '3px', textAlign: 'center' }}>
                        <div style={{ margin: 1, padding: 0, lineHeight: '1' }}></div>
                        {/* <div style={{ margin: 1, padding: 0, lineHeight: '1' }}>Patri Details</div> */}
                    </div>
                </Button>}
              

                {/* <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            window.gtag('event', 'Remedies');
                            console.log("remedies event");
                            location("/astroshop");
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/magic-wand.png" alt="WhatsApp" style={{ width: "35px", textDecoration: "none" }} />
                    <div style={{ fontSize: "15px", opacity: "1", marginTop: "2px" }}>Store</div>
                </Button> */}

                {(showBack) && <Button
                    style={{
                        // paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                         location("/chat/ria");
                    }}>
                    <img src="/icons/horoscope.png" alt="WhatsApp" style={{ width: "35px", textDecoration: "none" }} />
                    {/* <img src="/icons/horoscope.png" style={{ paddingRight: "3.5%", width:"50px", height:"50px" }} /> */}
                    <div style={{ fontSize: "15px", opacity: "1", marginTop: "3px" }}>Back</div>
                </Button>}

                {(false) && <Button
                    style={{
                        // paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                         location("/refer");
                    }}>
                    <img src="/icons/rupee-symbol.png" alt="WhatsApp" style={{ width: "35px", textDecoration: "none" }} />
                    {/* <img src="/icons/horoscope.png" style={{ paddingRight: "3.5%", width:"50px", height:"50px" }} /> */}
                    <div style={{ fontSize: "15px", opacity: "1", marginTop: "3px" }}></div>
                </Button>}

                
                {/* <GooglePlayBadge/> */}
                {showNumerology && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        dispatch({type: actionTypes.SET_SEARCH_TERM, term:"astro:"})
                        location("/astrobot");
                        
                    }}>
                    <img src="/icons/numerology.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Astrology</div>
                </Button>}

                {/* {showWrite && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/write")
                    }}>
                    <img src="/icons/pencil.png" alt="WhatsApp" style={{ width: "28px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Writer</div>
                </Button>} */}

                {false && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/business")
                    }}>
                    <img src="/icons/business.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Business</div>
                </Button>}

                {true && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            const url = "https://www.hiastro.in/"
                            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            window.open(whatsappUrl, '_blank');
                            window.gtag('event', 'Share');
                            mixpanelService.trackEvent('Shared',{
                                via: 'Whatsapp',
                                logged_in: userCred ? true: false
                            });
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "35px", marginBottom:"6px", textDecoration: "none" }} />
                </Button>}

            {true && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            // const url = "https://astrosky.co.in/"
                            // const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            // window.open(whatsappUrl, '_blank');
                            window.open("https://www.instagram.com/astroskyofficial/");
                            window.gtag('event', 'Share');
                            mixpanelService.trackEvent('Shared',{
                                via: 'Instagram',
                                logged_in: userCred ? true: false
                            });
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/instagram.png" alt="Instagram" style={{ width: "40px", marginBottom:"6px", textDecoration: "none" }} />
                </Button>}
            </div>
        </div>
    );
}

export default AppFooter;