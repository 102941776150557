import React, { useEffect, useState } from 'react';
import Header from './Header';
import AppFooter from './AppFooter';
import "./BasicChat.css";
import { useStateValue } from '../statemanagement/StateProvider';
import Disclaimer from '../components/Disclaimer';
import BirthDetailsDisplayComponent from '../components/BirthDetailsDisplayComponent';
import Entity from './Entity';
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import LiveDashas from './LiveDashas';
import { getUrl } from '../helpers/envUrls';
import HorizontalScrollButtons from '../components/HorizontalScrollButtons';
import { TypeAnimation } from 'react-type-animation';
import ReportView from './ReportView';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import WeeklyHoroscopeView from './WeeklyHoroscopeView';
import KundliMatchingView from './KundliMatchingView';
import MetaComponent from '../seo/dynamic';
import mixpanelService from '../mixpanelService';
import { usePageEngagement } from '../customhooks/usePageEngagement';

const Discover = () => {

    const [{ userCred, uid, dob, tob, city, horoscope, app_category, gender }, dispatch] = useStateValue();
    const [entities, setEntities] = useState([]);
    usePageEngagement();

    useEffect(() => {
        dispatch({ type: actionTypes.RESET_STATE });
    }, []);

    const location = useLocation();

    const categoryMap = {
        all: 'All',
        'new-entities': 'New',
        // 'personal-reports': 'In-depth Predictions',
        'personal-reports': 'Predictions',
        'free-live-predictions': 'Live',
        "free-career-predictions": 'Career Predictions',
        "free-love-predictions": 'Love Predictions',
        "free-numerology-predictions": 'Numerology',
        "free-vastu-predictions": 'Vastu',
        "free-wealth-predictions": 'Wealth',
        "free-marriage-predictions": 'Marriage Predictions',
        'free-feng-shui-predictions': 'Feng Shui',
        'free-life-coach-predictions': 'Life Coach',
        'kundli-matching': 'Kundli Matching'
    };

    useEffect(() => {

      let app_category = 'All';
      //  let app_category = 'Kundli Matching';

        const app_category_params = decodeURIComponent(location.pathname.split('/')[1] || '').toLowerCase();
        const exactMatch = categoryMap[app_category_params];

        if (!!exactMatch) {
            app_category = exactMatch;
        }

        dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: app_category });      
    }, [])

    useEffect(() => {
        const getEntitiesByCategory = async () => {
            const entityJSON = await axios.get(`${getUrl()}/entities`, {
                params: {
                    app_category: app_category,
                    gender: gender
                }
            });

            setEntities(entityJSON.data);
        };

        if (!!app_category && app_category != "") {
            getEntitiesByCategory();
        }
        mixpanelService.trackEvent('Viewed Category',{
            category: app_category,
            logged_in: userCred ? true: false
        })

    }, [app_category]);

    const EntitiesElem = entities.map((u, i) => {
        return (<div key={i}><Entity entityImageSrc={u.entityImageSrc} app_category={app_category}
            entityName={u.entityName}
            entityDescription={u.entityDescription}
            entityRating={u.entityRating}
            entityCode={u.entityCode} orders={u.orders}
            entityExpertise={u.entityExpertise}
            sampleQuestions={u.sampleQuestions.join(", ")} />

            <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} /></div>)
    })

    return (
        <>
        {<MetaComponent app_category={app_category}/>}

            <div>
                <div className="container">
                    <div className="container__header">
                        <Header userCred={userCred} showLogin={false} />
                    </div>
                    <div className="container__body" style={{ textAlign: "center" }}>

                        {/* <TypeAnimation sequence={[
                            'AstroSky is now HiAstro!',
                            3000,
                            '100% Privacy and No Data Stored',
                            3000,
                        ]} wrapper="span" speed={10} style={{ textAlign: "center", marginTop: "5%", fontSize: "1.3em", fontWeight: "450", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401" }} repeat={1} /> */}

                        <BirthDetailsDisplayComponent dob={dob} tob={tob} city={city} />

                        <HorizontalScrollButtons app_category={app_category} dispatch={dispatch} horoscope={horoscope} />


                        {app_category == "Live" && <><br /><br /><LiveDashas horoscope={horoscope} /></>}

                        {app_category == "Personal Reports" && <><ReportView /></>}

                        {app_category == "Kundli Matching" && <><KundliMatchingView /> </>}

                        {app_category == "Weekly Horoscope" && <><br /><br /><WeeklyHoroscopeView /></>}

                        {app_category != "Kundli Matching" && false && !Capacitor.isNativePlatform() && <div style={{ margin: "10px", color: "gray", fontSize: "1rem", fontWeight: "400" }}>
                            <p style={{ textAlign: "center", marginTop: "5%", fontSize: "1rem", fontWeight: "400", lineHeight: "2.1rem", display: "inline-block", color: "gray" }}>Download our App for Unlimited Free Chat</p>
                            <div style={{ marginTop: "25px" }}>
                                <img style={{ width: "11rem" }} src="/icons/googlePlay.png" onClick={(_) => {
                                    window.gtag('event', "app_cta");
                                    window.open("https://play.google.com/store/apps/details?id=com.astroskyandroid.app")
                                }} />
                            </div></div>}


                        {EntitiesElem}

                        <Disclaimer hindi={false} />

                        <div style={{ height: "30vh" }}></div>
                    </div>
                </div>
                {/* <AppFooter home={true} more={false} /> */}
            </div>
        </>
    );
};

export default Discover;
