import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CommentIcon from '@mui/icons-material/Comment';
import mixpanelService from '../mixpanelService';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';
import { useStateValue } from "../statemanagement/StateProvider"
import { calculateAge } from '../helper/calculateAge';
import { Rating } from '@mui/material';

export default function FeedbackComponent({isFeedbackOpen, setIsFeedbackOpen, onFeedbackSubmitted }) {

  const [feedback, setFeedback] = useState("");
  const [{ userCred, entity, gender, app_category, dob, tob, city }, _] = useStateValue();
  const [rating, setRating] = useState(0);

  const handleClose = () => {
    // setRating(0);
    setFeedback("");
    setIsFeedbackOpen(false);
  };

  const submitFeedback = (feedback) => {

    let trackingObj = {
      entity: entity,
      logged_in: userCred ? true : false,
      dob: dob,
      tob: tob,
      birth_city: city,
      age: calculateAge(dob),
      gender: gender,
      category: app_category
    };

    if(feedback.trim() == "" && rating == 0) {return;}
    // if(feedback.trim() == "" && ) {return;}

    if(rating>0){
      trackingObj = {...trackingObj, rating: rating}
    }
    if(feedback.trim() != ""){
      trackingObj = {...trackingObj, feedback: feedback}
    }

    mixpanelService.trackEvent(`Feedback`, trackingObj);
    onFeedbackSubmitted(true);
    handleClose();
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isFeedbackOpen}
      onClose={handleClose}
      onOpen={handleClose}
    >
      <Box sx={{ width: 'auto' }} role="presentation">
        <List>
          <ListItem sx={{textAlign:"center", fontSize:"1.4rem"}}>
          <ListItemText
            primary={`Feedback for ${entity.charAt(0).toUpperCase() + entity.slice(1)}`}
          />
          </ListItem>

          <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
          <Rating
            name="half-rating"
            value={rating}
            onChange={(_, newValue) => setRating(newValue)}
            precision={0.5}
          />
        </ListItem>

          {/* <ListItem disablePadding onClick={(_)=>{submitFeedback("Answer is right")}}>
            <ListItemButton>
              <ListItemIcon>
                <GppGoodIcon />
              </ListItemIcon>
              <ListItemText primary="Answer is right" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={(_)=>{submitFeedback("I prefer short answers")}}>
            <ListItemButton>
              <ListItemIcon>
              <ShortTextIcon />
              </ListItemIcon>
              <ListItemText primary="I prefer short answers" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={(_)=>{submitFeedback("I prefer long answers")}}>
            <ListItemButton>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              <ListItemText primary="I prefer long answers" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={(_)=>{submitFeedback("Answer is wrong")}}>
            <ListItemButton>
              <ListItemIcon>
              <GppBadIcon />
              </ListItemIcon>
              <ListItemText primary="Answer is wrong" />
            </ListItemButton>
          </ListItem> */}

          <ListItem>
            <Box display="flex" alignItems="center" width="100%">
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                <CommentIcon />
              </ListItemIcon>
              <TextField
                fullWidth
                placeholder="Enter feedback here"
                variant="outlined"
                value={feedback}
                onChange={(e)=>setFeedback(e.currentTarget.value)}
              />
            </Box>
          </ListItem>

          <ListItem>
            <Button
              variant="contained"
              onClick={() => submitFeedback(feedback)}
              fullWidth
              sx={{background: "bisque",
                color: "black",
                boxShadow: "none",
                borderRadius: "12px"}}
            >
              Send
            </Button>
          </ListItem>
          {/* <ListItem>
            <Button
              variant="contained"
              onClick={() => handleClose()}
              fullWidth
              sx={{background: "bisque",
                color: "black",
                boxShadow: "none",
                borderRadius: "12px"}}
            >
              Close
            </Button>
          </ListItem> */}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}
