import React from 'react';
import { Helmet } from 'react-helmet';

export const generateDynamicTitle = (app_category) => {
    let titleTxt = 'Free Astrology Chat Powered by AI | HiAstro';

    switch (app_category) {
        case 'Kundli Matching':
            titleTxt = 'Perfect Match: Kundali Matching for Love & Marriage | HiAstro';
            break;
        case 'Marriage Predictions':
            titleTxt = 'Free Marriage Prediction Online - HiAstro';
            break;
        case 'Love Predictions':
            titleTxt = 'Free Love Prediction Online - HiAstro';
            break;
        case 'Career Predictions':
            titleTxt = 'Free Career Prediction Online - HiAstro';
            break;
        case 'Wealth':
            titleTxt = 'Free Wealth Prediction Online | HiAstro';
            break;
        case 'Numerology':
            titleTxt = 'Free Numerology Prediction | HiAstro';
            break;
        case 'Vastu':
            titleTxt = 'Free Vastu Prediction Online – Expert Advice by HiAstro';
            break;
        case 'Feng Shui':
            titleTxt = 'Free Feng Shui Prediction Online | HiAstro';
            break;
        case 'Life Coach':
            titleTxt = 'Free Life Coach Prediction | Expert Guidance by HiAstro';
            break;  
        case 'Live':
            titleTxt = 'Free Live Astrology Predictions | HiAstro Experts';
            break;
        default:
            titleTxt = 'Free Astrology Chat-Powered by AI | HiAstro';
    }
    return titleTxt;
};

export const getKeywordsByCategory = (app_category) => {
    //content="Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
    let keywords = "Real + Gen AI astrologer , AI prediction, ai astrology ,ai astrologer ,ai astrology free chat ,ai astrology website"
    switch (app_category){
        case 'Kundli Matching':
            keywords = "Kundli Matching, HiAstro Kundali Report, Horoscope matching for marriage, Find your life partner, Kundli match online, Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
            break;
        case 'Marriage Predictions':
            keywords = "Free marriage prediction, Top-rated astrologer Pooja,AI astrology for marriage,Personalized marriage forecast"
            break;
        case 'Love Predictions':
            keywords = "Free love compatibility, Love life prediction,Future relationship forecast,Astrology for love compatibility,HiAstro free love astrology"
            break;
        case 'Career Predictions':
            keywords = "Free career prediction, Career advice by astrologers,Career path prediction free,Gen AI career astrology insights,Free job prediction astrology"
            break;
        case 'Wealth':
            keywords = "Free Wealth Prediction, Wealth Horoscope,Financial Prediction Online,Astrology for Wealth,Free Financial Insights"
            break;
        case 'Numerology':
            keywords = "Free Numerology Prediction, Numerology Online ,Accurate Numerology Reading ,Free Numerology Calculator ,HiAstro Predictions"
            break;
        case 'Vastu':
            keywords = "Free Vastu Prediction, Vastu for Home and Office ,Vastu Remedies ,Free Vastu Consultation Online"
            break;
        case 'Feng Shui':
            keywords = "Free Feng Shui Prediction, Feng Shui for Prosperity ,Harmony Through Feng Shui ,Feng Shui Predictions Online"
            break;
        case 'Life Coach':
            keywords = "Free Life Coach Prediction, Astrology-based life coaching, free life predictions, personalized life coaching insights, HiAstro life coach prediction"
            break;  
        case 'Live':
            keywords = "Free live prediction HiAstro, Accurate astrology predictions ,Free online horoscope reading ,Live astrology chat"
            break;
        default:
            keywords = "Free Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
    }
    
    return keywords;
}
export const generateDynamicDescription = (app_category) => {
    let descriptionTxt = "Experience free astrology chat with real astrologers and AI-powered insights at HiAstro. Get instant predictions and guidance 24/7, all at no cost!";
    switch (app_category) {
        case 'Kundli Matching':
            descriptionTxt = 'Free Chat with a Kundli matching astrologer on HiAstro, an AI-powered astrology platform. Get instant answers to all your Kundli Milan questions from our expert AI astrologer.';
            break;
        case 'Marriage Predictions':
            descriptionTxt = "Get free marriage predictions online with HiAstro. Expert astrologers reveal insights about your married life and future compatibility. Try it now for free!.";
            break;
        case 'Love Predictions':
            descriptionTxt = "Get free love predictions online with HiAstro. Discover insights about your love life, compatibility, and future relationships. Try it now for free!";
            break;
        case 'Career Predictions':
            descriptionTxt = "Get free career predictions online at HiAstro. Expert astrologers and Gen AI insights reveal your career path. Start your journey to success today for free!";
            break;
        case 'Wealth':
            descriptionTxt = "Get free wealth predictions with HiAstro! Explore financial insights, opportunities, and guidance through your horoscope. Unlock your wealth potential today!";
            break;
        case 'Numerology':
            descriptionTxt = "Discover your life's hidden secrets with free numerology predictions. Get accurate insights online at HiAstro and unlock your true potential today.";
            break;
        case 'Vastu':
            descriptionTxt = "Get free Vastu prediction online at HiAstro. Expert advice and remedies to enhance harmony, prosperity, and success in your home or office.";
            break;
        case 'Feng Shui':
            descriptionTxt = "Get free Feng Shui predictions online with HiAstro. Discover tips to attract wealth, harmony, and success in your life. Try now for a balanced and prosperous future!";
            break;
        case 'Life Coach':
            descriptionTxt = "Get free life coach predictions with HiAstro. Unlock your potential, gain clarity, and achieve goals through personalized astrology-based insights.";
            break;  
        case 'Live':
            descriptionTxt = "Experience accurate free live predictions by expert astrologers on HiAstro. Get real-time answers for career, love, and health. Try now for free insights!";
            break;
        default:
            descriptionTxt = "Get free, accurate predictions with HiAstro's AI-powered astrology for love, career, and marriage. Explore expert insights with Vastu, tarot, numerology, and detailed Kundali reports.";
    }
    return descriptionTxt;
};

const MetaComponent = ({ app_category }) => {
    return (
        <Helmet>
            <title>{generateDynamicTitle(app_category)}</title>
            <meta name="description" content={generateDynamicDescription(app_category)} />
            <meta
                name="keywords"
                content = {getKeywordsByCategory(app_category)}
            />
        </Helmet>
    );
};

export default MetaComponent;