import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../statemanagement/StateProvider";
import "./BasicChat.css"; 
import { actionTypes } from "../statemanagement/reducer";
import RightSidebar from "../components/RightSidebar";

const Header = ({ showLine = true, displayAI = true }) => {
    const [_,dispatch] = useStateValue(); 
    const navigate = useNavigate(); 

    return (<div className="headerStyle" >
        <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
            <div>
                <div style={{ display: "flex", alignItems:"center" }} onClick={_ => { navigate("/"); }}>
                    {/* <img src="/icons/horoscope.png" alt="HiAstro" style={{ paddingRight: "3.5%", width:"45px", height:"45px" }} /> */}
                    <div className="headerStyle_Label">
                        {/* <h1 style={{ fontSize: "30px", fontWeight: 500, marginLeft: "2%", opacity: "0.9", width:"fit-content" }}>HiAstro</h1> */}
                        {/* <svg width="100" height="50" viewBox="0 0 51 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 9.5C16.3629 4.14811 21.9043 1 28.398 1C35.4847 1 41.5585 5.33616 44.1111 11.5C44.3136 11.9889 44.4939 12.4893 44.6508 13" stroke="#1F4C7E"/>
                            <path d="M0.758789 21V10.8145H2.86426V14.9023H7.19141V10.8145H9.29004V21H7.19141V16.707H2.86426V21H0.758789Z" fill="#1F4C7E"/>
                            <path d="M12.9473 21L18.1357 10.8145H21.376L23.208 21H20.542L20.2617 19.0381H16.6455L15.7021 21H12.9473ZM17.582 17.1172H19.9883L19.8994 16.5225C19.7559 15.3877 19.6123 14.2734 19.4756 13.0225C18.9219 14.2734 18.4092 15.3877 17.8691 16.5225L17.582 17.1172ZM26.9268 21.1777C25.0332 21.1777 23.707 20.2891 23.707 18.8057C23.707 18.7646 23.707 18.7305 23.707 18.6895H26.0176C25.9902 19.25 26.3457 19.5576 27.0156 19.5576C27.6719 19.5576 28.2051 19.2568 28.2051 18.8262C28.2051 18.5664 27.9795 18.4229 27.5146 18.334L26.2432 18.1016C24.9717 17.8623 24.2949 17.2197 24.2949 16.2217C24.2949 14.6768 25.7852 13.6309 27.8428 13.6309C29.709 13.6309 30.8916 14.4443 30.8916 15.7568C30.8916 15.8662 30.8848 16.0029 30.8643 16.126H28.7041C28.7588 15.5723 28.3965 15.2441 27.7812 15.2441C27.1455 15.2441 26.6738 15.5723 26.6738 15.9824C26.6738 16.2422 26.8789 16.3926 27.2754 16.4678L28.6836 16.7344C30.0166 16.9873 30.6318 17.5342 30.6318 18.4844C30.6318 20.0498 29.0391 21.1777 26.9268 21.1777ZM37.0986 13.7812L36.791 15.5996H35.3145L34.8223 18.5732C34.7471 19.0381 34.8701 19.1816 35.3896 19.1816H36.21L35.9092 21H34.5762C32.8398 21 32.1699 20.3711 32.4023 18.9697L32.9561 15.5996H31.7188L32.0264 13.7812H33.2568L33.585 11.8125H35.9434L35.6152 13.7812H37.0986ZM36.8525 21L38.0488 13.7812H40.3252L40.1133 15.0527H40.1338C40.5508 14.1367 41.1455 13.7539 42.1162 13.7539C42.3623 13.7539 42.5605 13.7607 42.752 13.7676L42.4307 15.709C42.2666 15.7021 41.8428 15.6885 41.501 15.6885C40.6602 15.6885 39.9902 16.2764 39.8193 17.3018L39.2041 21H36.8525ZM45.9238 21.1641C43.873 21.1641 42.5879 19.9473 42.5879 18.0332C42.5879 15.5381 44.3789 13.6172 46.9287 13.6172C48.9863 13.6172 50.2646 14.8408 50.2646 16.7549C50.2646 19.1338 48.583 21.1641 45.9238 21.1641ZM46.0742 19.291C47.2842 19.291 47.9131 17.9238 47.9131 16.7412C47.9131 15.9209 47.4209 15.4902 46.7783 15.4902C45.582 15.4902 44.9395 16.8506 44.9395 18.0469C44.9395 18.8672 45.4316 19.291 46.0742 19.291Z" fill="#FFCC3F"/>
                            <path d="M11.75 14V21" stroke="#1F4C7E" stroke-width="2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2333 13.0897C12.1962 13.1863 12.1034 13.25 12 13.25C11.8966 13.25 11.8038 13.1863 11.7667 13.0897L11.3643 12.0435C11.3033 11.8849 11.2867 11.847 11.2654 11.817C11.2427 11.7851 11.2149 11.7573 11.183 11.7346C11.153 11.7133 11.1151 11.6967 10.9565 11.6357L9.91026 11.2333C9.81371 11.1962 9.75 11.1034 9.75 11C9.75 10.8966 9.81371 10.8038 9.91026 10.7667L10.9565 10.3643C11.1151 10.3033 11.153 10.2867 11.183 10.2654C11.2149 10.2427 11.2427 10.2149 11.2654 10.183C11.2867 10.153 11.3033 10.1151 11.3643 9.95647L11.7667 8.91025C11.8038 8.81371 11.8966 8.75 12 8.75C12.1034 8.75 12.1962 8.81371 12.2333 8.91026L12.6357 9.95647C12.6967 10.1151 12.7133 10.153 12.7346 10.183C12.7573 10.2149 12.7851 10.2427 12.817 10.2654C12.847 10.2867 12.8849 10.3033 13.0435 10.3643L14.0897 10.7667C14.1863 10.8038 14.25 10.8966 14.25 11C14.25 11.1034 14.1863 11.1962 14.0897 11.2333L13.0435 11.6357C12.8849 11.6967 12.847 11.7133 12.817 11.7346C12.7851 11.7573 12.7573 11.7851 12.7346 11.817C12.7133 11.847 12.6967 11.8849 12.6357 12.0435L12.2333 13.0897Z" fill="#FFCC3F"/>
                            </svg> */}
                            <svg width="110" height="51" viewBox="0 0 51 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 9.5C16.3629 4.14811 21.9043 1 28.398 1C35.4847 1 41.5585 5.33616 44.1111 11.5C44.3136 11.9889 44.4939 12.4893 44.6508 13" stroke="black"/>
                            <path d="M0.758789 21V10.8145H2.86426V14.9023H7.19141V10.8145H9.29004V21H7.19141V16.707H2.86426V21H0.758789Z" fill="black"/>
                            <path d="M12.9473 21L18.1357 10.8145H21.376L23.208 21H20.542L20.2617 19.0381H16.6455L15.7021 21H12.9473ZM17.582 17.1172H19.9883L19.8994 16.5225C19.7559 15.3877 19.6123 14.2734 19.4756 13.0225C18.9219 14.2734 18.4092 15.3877 17.8691 16.5225L17.582 17.1172ZM26.9268 21.1777C25.0332 21.1777 23.707 20.2891 23.707 18.8057C23.707 18.7646 23.707 18.7305 23.707 18.6895H26.0176C25.9902 19.25 26.3457 19.5576 27.0156 19.5576C27.6719 19.5576 28.2051 19.2568 28.2051 18.8262C28.2051 18.5664 27.9795 18.4229 27.5146 18.334L26.2432 18.1016C24.9717 17.8623 24.2949 17.2197 24.2949 16.2217C24.2949 14.6768 25.7852 13.6309 27.8428 13.6309C29.709 13.6309 30.8916 14.4443 30.8916 15.7568C30.8916 15.8662 30.8848 16.0029 30.8643 16.126H28.7041C28.7588 15.5723 28.3965 15.2441 27.7812 15.2441C27.1455 15.2441 26.6738 15.5723 26.6738 15.9824C26.6738 16.2422 26.8789 16.3926 27.2754 16.4678L28.6836 16.7344C30.0166 16.9873 30.6318 17.5342 30.6318 18.4844C30.6318 20.0498 29.0391 21.1777 26.9268 21.1777ZM37.0986 13.7812L36.791 15.5996H35.3145L34.8223 18.5732C34.7471 19.0381 34.8701 19.1816 35.3896 19.1816H36.21L35.9092 21H34.5762C32.8398 21 32.1699 20.3711 32.4023 18.9697L32.9561 15.5996H31.7188L32.0264 13.7812H33.2568L33.585 11.8125H35.9434L35.6152 13.7812H37.0986ZM36.8525 21L38.0488 13.7812H40.3252L40.1133 15.0527H40.1338C40.5508 14.1367 41.1455 13.7539 42.1162 13.7539C42.3623 13.7539 42.5605 13.7607 42.752 13.7676L42.4307 15.709C42.2666 15.7021 41.8428 15.6885 41.501 15.6885C40.6602 15.6885 39.9902 16.2764 39.8193 17.3018L39.2041 21H36.8525ZM45.9238 21.1641C43.873 21.1641 42.5879 19.9473 42.5879 18.0332C42.5879 15.5381 44.3789 13.6172 46.9287 13.6172C48.9863 13.6172 50.2646 14.8408 50.2646 16.7549C50.2646 19.1338 48.583 21.1641 45.9238 21.1641ZM46.0742 19.291C47.2842 19.291 47.9131 17.9238 47.9131 16.7412C47.9131 15.9209 47.4209 15.4902 46.7783 15.4902C45.582 15.4902 44.9395 16.8506 44.9395 18.0469C44.9395 18.8672 45.4316 19.291 46.0742 19.291Z" fill="#F3781C"/>
                            <path d="M11.75 14V21" stroke="black" stroke-width="2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2333 13.0897C12.1962 13.1863 12.1034 13.25 12 13.25C11.8966 13.25 11.8038 13.1863 11.7667 13.0897L11.3643 12.0435C11.3033 11.8849 11.2867 11.847 11.2654 11.817C11.2427 11.7851 11.2149 11.7573 11.183 11.7346C11.153 11.7133 11.1151 11.6967 10.9565 11.6357L9.91026 11.2333C9.81371 11.1962 9.75 11.1034 9.75 11C9.75 10.8966 9.81371 10.8038 9.91026 10.7667L10.9565 10.3643C11.1151 10.3033 11.153 10.2867 11.183 10.2654C11.2149 10.2427 11.2427 10.2149 11.2654 10.183C11.2867 10.153 11.3033 10.1151 11.3643 9.95647L11.7667 8.91025C11.8038 8.81371 11.8966 8.75 12 8.75C12.1034 8.75 12.1962 8.81371 12.2333 8.91026L12.6357 9.95647C12.6967 10.1151 12.7133 10.153 12.7346 10.183C12.7573 10.2149 12.7851 10.2427 12.817 10.2654C12.847 10.2867 12.8849 10.3033 13.0435 10.3643L14.0897 10.7667C14.1863 10.8038 14.25 10.8966 14.25 11C14.25 11.1034 14.1863 11.1962 14.0897 11.2333L13.0435 11.6357C12.8849 11.6967 12.847 11.7133 12.817 11.7346C12.7851 11.7573 12.7573 11.7851 12.7346 11.817C12.7133 11.847 12.6967 11.8849 12.6357 12.0435L12.2333 13.0897Z" fill="#F3781C"/>
                            </svg>

                            {/* <svg width="100" height="50" viewBox="0 0 51 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 9.5C16.3629 4.14811 21.9043 1 28.398 1C35.4847 1 41.5585 5.33616 44.1111 11.5C44.3136 11.9889 44.4939 12.4893 44.6508 13" stroke="#1F4C7E"/>
                            <path d="M0.758789 21V10.8145H2.86426V14.9023H7.19141V10.8145H9.29004V21H7.19141V16.707H2.86426V21H0.758789Z" fill="#1F4C7E"/>
                            <path d="M12.9473 21L18.1357 10.8145H21.376L23.208 21H20.542L20.2617 19.0381H16.6455L15.7021 21H12.9473ZM17.582 17.1172H19.9883L19.8994 16.5225C19.7559 15.3877 19.6123 14.2734 19.4756 13.0225C18.9219 14.2734 18.4092 15.3877 17.8691 16.5225L17.582 17.1172ZM26.9268 21.1777C25.0332 21.1777 23.707 20.2891 23.707 18.8057C23.707 18.7646 23.707 18.7305 23.707 18.6895H26.0176C25.9902 19.25 26.3457 19.5576 27.0156 19.5576C27.6719 19.5576 28.2051 19.2568 28.2051 18.8262C28.2051 18.5664 27.9795 18.4229 27.5146 18.334L26.2432 18.1016C24.9717 17.8623 24.2949 17.2197 24.2949 16.2217C24.2949 14.6768 25.7852 13.6309 27.8428 13.6309C29.709 13.6309 30.8916 14.4443 30.8916 15.7568C30.8916 15.8662 30.8848 16.0029 30.8643 16.126H28.7041C28.7588 15.5723 28.3965 15.2441 27.7812 15.2441C27.1455 15.2441 26.6738 15.5723 26.6738 15.9824C26.6738 16.2422 26.8789 16.3926 27.2754 16.4678L28.6836 16.7344C30.0166 16.9873 30.6318 17.5342 30.6318 18.4844C30.6318 20.0498 29.0391 21.1777 26.9268 21.1777ZM37.0986 13.7812L36.791 15.5996H35.3145L34.8223 18.5732C34.7471 19.0381 34.8701 19.1816 35.3896 19.1816H36.21L35.9092 21H34.5762C32.8398 21 32.1699 20.3711 32.4023 18.9697L32.9561 15.5996H31.7188L32.0264 13.7812H33.2568L33.585 11.8125H35.9434L35.6152 13.7812H37.0986ZM36.8525 21L38.0488 13.7812H40.3252L40.1133 15.0527H40.1338C40.5508 14.1367 41.1455 13.7539 42.1162 13.7539C42.3623 13.7539 42.5605 13.7607 42.752 13.7676L42.4307 15.709C42.2666 15.7021 41.8428 15.6885 41.501 15.6885C40.6602 15.6885 39.9902 16.2764 39.8193 17.3018L39.2041 21H36.8525ZM45.9238 21.1641C43.873 21.1641 42.5879 19.9473 42.5879 18.0332C42.5879 15.5381 44.3789 13.6172 46.9287 13.6172C48.9863 13.6172 50.2646 14.8408 50.2646 16.7549C50.2646 19.1338 48.583 21.1641 45.9238 21.1641ZM46.0742 19.291C47.2842 19.291 47.9131 17.9238 47.9131 16.7412C47.9131 15.9209 47.4209 15.4902 46.7783 15.4902C45.582 15.4902 44.9395 16.8506 44.9395 18.0469C44.9395 18.8672 45.4316 19.291 46.0742 19.291Z" fill="#F2B613"/>
                            <path d="M11.75 14V21" stroke="#1F4C7E" stroke-width="2"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2333 13.0897C12.1962 13.1863 12.1034 13.25 12 13.25C11.8966 13.25 11.8038 13.1863 11.7667 13.0897L11.3643 12.0435C11.3033 11.8849 11.2867 11.847 11.2654 11.817C11.2427 11.7851 11.2149 11.7573 11.183 11.7346C11.153 11.7133 11.1151 11.6967 10.9565 11.6357L9.91026 11.2333C9.81371 11.1962 9.75 11.1034 9.75 11C9.75 10.8966 9.81371 10.8038 9.91026 10.7667L10.9565 10.3643C11.1151 10.3033 11.153 10.2867 11.183 10.2654C11.2149 10.2427 11.2427 10.2149 11.2654 10.183C11.2867 10.153 11.3033 10.1151 11.3643 9.95647L11.7667 8.91025C11.8038 8.81371 11.8966 8.75 12 8.75C12.1034 8.75 12.1962 8.81371 12.2333 8.91026L12.6357 9.95647C12.6967 10.1151 12.7133 10.153 12.7346 10.183C12.7573 10.2149 12.7851 10.2427 12.817 10.2654C12.847 10.2867 12.8849 10.3033 13.0435 10.3643L14.0897 10.7667C14.1863 10.8038 14.25 10.8966 14.25 11C14.25 11.1034 14.1863 11.1962 14.0897 11.2333L13.0435 11.6357C12.8849 11.6967 12.847 11.7133 12.817 11.7346C12.7851 11.7573 12.7573 11.7851 12.7346 11.817C12.7133 11.847 12.6967 11.8849 12.6357 12.0435L12.2333 13.0897Z" fill="#F2B613"/>
                            </svg> */}

                        { displayAI && <h5><i style={{opacity:"0.4", fontWeight:400, paddingRight:"2px" }}>Powered by AI</i></h5>}
                        {/* { displayAI && <h5><i style={{opacity:"0.5", fontWeight:500, padding: "2px" }}>Powered by AI</i></h5>}
                        { !displayAI && <h5><i style={{padding: "2px", display:"none" }}>Powered by AI</i></h5>} */}
                    </div> 
                </div>
            </div>
            
            <div style={{ display: "flex"}}>
                <RightSidebar />
            </div>
            </div>
        {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
    </div>);
}

export default Header;